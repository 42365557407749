<template>
  <div class="Scales bg-white">
    <Row class="px-1">
      <i-col span="18" class="align-justify">
        <Button type="default" icon="md-refresh" size="large" class="mr-1"></Button>
        <i-input
          v-model="keyword"
          placeholder="输入量表名称进行查找"
          size="large"
          style="width: 300px"
          class="el-inline mr-1"
        >
          <Button slot="append" icon="md-search" style="width: 40px" @click="HandleSearch"></Button>
        </i-input>
        <Select
          v-model="scaleTypeName"
          size="large"
          style="width:260px"
          clearable
          @on-clear="clearSelect"
          @on-select="selectScaleType"
        >
          <Option
            v-for="item in scaleTypeList"
            :value="item.value"
            :key="item.value"
          >{{ item.label }}</Option>
        </Select>
        <!-- <Button type="default" size="large" class="mx-1">批量{{deOrRe}}</Button>
        <Button type="default" size="large" class="mr-1">批量导出</Button>
        <Button type="default" size="large" @click="mulInfoEdite()">批量编辑</Button>-->
      </i-col>
      <i-col span="6" class="align-right colHeigth">
        <Checkbox @on-change="changeStatus">被关闭量表</Checkbox>
      </i-col>
      <i-col span="24">
        <Table
          border
          ref="scalesTable"
          :loading="searching"
          :columns="tColumns"
          :data="tData"
          class="mt-1"
          @on-select="onSelect"
        >
          <div slot-scope="{ row }" slot="action">
            <Button type="primary" size="small" style="margin-right: 5px" @click="infoEdite(row)">详情</Button>
            <!-- <Button
              type="success"
              size="small"
              disabled
              style="margin-right: 5px"
              @click="verdict(row)"
            >结论自定义</Button>-->
            <Button size="small" @click="closeScale(row,deOrRe)">{{deOrRe}}</Button>
          </div>
        </Table>
        <Page
          ref="usersPagination"
          :total="recordTotal"
          :page-size="20"
          @on-change="changePage"
          @on-page-size-change="changeSize"
          show-total
          show-sizer
          show-elevator
          class="p-1 align-right"
        />
      </i-col>
    </Row>
    <Modal
      footer-hide
      v-model="scaleInfo"
      width="1200"
      :mask-closable="false"
      :title="scaleInfoForm.modalTitle"
      ok-text="提交"
      cancel-text="取消"
      :on-ok="postNewInfo"
      :styles="{top: '40px'}"
    >
      <i-form :model="scaleInfoForm" :label-width="70">
        <Row>
          <i-col span="12">
            <FormItem label="名称" v-show="scaleInfoForm.isShow">
              <i-input v-model="scaleInfoForm.scaleName"></i-input>
            </FormItem>
          </i-col>
          <i-col span="3">
            <FormItem label="原价(元)">
              <InputNumber v-model="scaleInfoForm.price" :min="0" :step="0.5" style="width: 100%;"></InputNumber>
            </FormItem>
          </i-col>
          <i-col span="3">
            <FormItem label="折扣">
              <InputNumber
                v-model="scaleInfoForm.discount"
                :min="0"
                :max="1"
                :step="0.05"
                style="width: 100%;"
              ></InputNumber>
            </FormItem>
          </i-col>
          <i-col span="6">
            <FormItem label="类型">
              <Select v-model="scaleInfoForm.type" clearable @on-select="selectInfoScaleType">
                <Option
                  v-for="item in scaleTypeList"
                  :value="item.value"
                  :key="item.value"
                >{{ item.label }}</Option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="图片" v-show="scaleInfoForm.isShow">
              <Upload
                type="drag"
                :format="['jpg','jpeg','png']"
                :on-success="handleSuccess"
                :on-error="handleError"
                :max-size="2048"
                :show-upload-list="false"
                :on-format-error="handleFormatError"
                :on-exceeded-size="handleMaxSize"
                action="https://swapi.hxypsy.cn/api/Scale/UploadImage"
              >
                <div style="padding: 20px 0">
                  <Icon type="ios-cloud-upload" size="80" style="color: #3399ff"></Icon>
                  <p>点击或拖动图片到这里上传</p>
                </div>
              </Upload>
            </FormItem>
          </i-col>
          <i-col span="4">
            <FormItem label v-show="scaleInfoForm.isShow">
              <Avatar
                shape="square"
                :src="scaleimages"
                size="large"
                style="width: 100px;height: 100px;line-height:100px"
              />
            </FormItem>
          </i-col>
        </Row>
        <Row>
          <i-col span="12">
            <FormItem label="介绍" v-show="scaleInfoForm.isShow">
              <editor-bar v-model="scaleInfoForm.describe" :isClear="isClear" @change="changeDesc"></editor-bar>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="指导语" v-show="scaleInfoForm.isShow" style="margin-bottom: 0rem;">
              <editor-bar v-model="scaleInfoForm.hint" :isClear="isClear" @change="changeHint"></editor-bar>
            </FormItem>
          </i-col>
        </Row>
        <Row>
          <i-col span="24">
            <Button
              type="primary"
              style="float:right;margin-left:10px;"
              :loading="modalLoading"
              @click="postNewInfo"
            >确认</Button>
            <Button style="float:right" @click="cancelAdd">取消</Button>
          </i-col>
        </Row>
        <!-- <span class="color-warning singnText">{{signText}}</span> -->
      </i-form>
    </Modal>
  </div>
</template>

<script>
import EditorBar from "../components/wangeditor";
export default {
  name: "Scales",
  components: {
    EditorBar,
  },
  data() {
    return {
      modalLoading: false,
      imagesPath: "",
      scaleimages: "",
      isClear: false,
      typeId: -1,
      bindStatus: 1,
      searching: true,
      pageSize: 20,
      recordTotal: 0,
      pageNum: 1,
      tData: [],
      keyword: "",
      deOrRe: "关闭",
      isDetach: false,
      scaleInfo: false,
      theight: window.innerHeight - 175,
      tColumns: [
        {
          type: "selection",
          width: 35,
          align: "center",
        },
        {
          type: "index",
          title: "序号",
          width: 45,
          align: "center",
        },
        {
          title: "名称",
          key: "scaleName",
          minWidth: 120,
          tooltip: true,
        },
        {
          title: "图片",
          minWidth: 80,
          key: "images",
          align: "center",
          render: (h, params) => {
            let devicesArr = [];
            let photo = [];
            photo.push("https://swapi.hxypsy.cn" + params.row.images);
            devicesArr.push(
              h(
                "viewer",
                {
                  props: {
                    images: photo,
                  },
                },
                [
                  h("img", {
                    attrs: {
                      src: photo[0],
                      style: "width: 60px;height:60px;margin-right:5px",
                    },
                  }),
                ]
              )
            );
            return h("div", devicesArr);
          },
        },
        {
          title: "原价(元)",
          key: "price",
          minWidth: 30,
          sortable: true,
        },
        {
          title: "折扣",
          key: "disCount",
          minWidth: 20,
          sortable: true,
        },
        {
          title: "现价(元)",
          key: "disPrice",
          render: (h, params) => {
            let disPrice = params.row.price * params.row.disCount;
            return h("span", this.myjs.formatDecimal(disPrice));
          },
          minWidth: 30,
          sortable: true,
        },
        {
          title: "类型",
          key: "scaleTypeName",
          minWidth: 50,
        },
        {
          title: "使用次数",
          key: "useTimes",
          minWidth: 20,
          sortable: true,
        },
        // {
        //   title: "指导语",
        //   key: "hint",
        //   minWidth: 200,
        //   tooltip: true,
        // },
        // {
        //   title: "简介",
        //   key: "describe",
        //   minWidth: 200,
        //   tooltip: true,
        // },
        {
          title: "操作",
          slot: "action",
          key: "scaleId",
          width: 120,
        },
      ],
      scaleTypeList: [],
      scaleTypeName: "",
      rows: [],
      signText: "",
      scaleInfoForm: {
        isShow: true,
        modalTitle: "量表详情",
        scaleName: "",
        type: "",
        price: 0,
        discount: 1,
        classes: [],
        introContent: "",
        leadContent: "",
        iamges: "",
      },
      editorOption: {
        placeholder: "",
        theme: "bubble",
      },
    };
  },
  mounted() {
    window.addEventListener(
      "resize",
      () => (this.theight = window.innerHeight - 175),
      false
    );
    // 获取量表列表
    this.$get("Scale/GetScaleList", {
      typeId: -1,
      keyword: "",
      statusCode: 1,
      pageSize: 20,
      pageNum: 1,
    }).then((scale) => {
      this.recordTotal = scale.data.total;
      this.tData = scale.data.responseList;
      // 获取用户列表
      this.$get("Scale/GetTypeList", {}).then((res) => {
        this.scaleTypeList = res.data.responseList;
        this.searching = false;
        this.$Message.success({
          content: "加载成功",
          duration: 3,
        });
      });
    });
  },
  methods: {
    clearFileList() {},
    insertUser() {},
    cancelAdd() {
      this.scaleInfo = false;
    },
    postNewInfo() {
      console.log(this.scaleInfoForm);
      console.log(this.imagesPath);
      this.$Modal.confirm({
        title: "提示",
        content: "<p>确定发布?</p>",
        loading: true,
        onOk: () => {
          this.$post("Scale/UpdateScale", {
            ScaleId: this.scaleInfoForm.scaleId,
            ScaleName: this.scaleInfoForm.scaleName,
            Price: this.scaleInfoForm.price,
            DisCount: this.scaleInfoForm.discount,
            Images: this.imagesPath,
            Describe: this.scaleInfoForm.describe,
            Hint: this.scaleInfoForm.hint,
            ScaleTypeId: this.scaleInfoForm.type,
          }).then((res) => {
            console.log(res);
            if (res.data.code) {
              this.$Modal.remove();
              this.$Message.success(res.data.msg);
              this.scaleInfo = false;
              this.getScale();
            } else {
              this.$Modal.remove();
              this.$Message.error(res.data.msg);
            }
          });
        },
        onCancel: () => {},
      });
    },
    handleError() {
      this.$Message.error("上传图片失败，请重试");
    },
    handleSuccess(res, file) {
      this.imagesPath = res;
      this.scaleimages = "https://swapi.hxypsy.cn" + res;
      this.$Message.success("上传图片成功");
    },
    handleFormatError(file) {
      this.$Notice.error({
        title: "错误",
        desc: "文件 " + file.name + " 格式错误, 请选择 jpg 或者 png.",
      });
    },
    handleMaxSize(file) {
      this.$Notice.error({
        title: "错误",
        desc: "文件 " + file.name + " 过大, 请选择不超过2M的文件.",
      });
    },
    isClearHint() {},
    changeHint(val) {},
    isClearDesc() {},
    changeDesc(val) {},
    clearSelect() {
      this.typeId = -1;
      this.pageNum = 1;
      this.getScale();
    },
    changeSize(e) {
      this.pageSize = e;
      this.getScale();
    },
    changePage(e) {
      this.pageNum = e;
      this.getScale();
    },
    selectScaleType(e) {
      this.typeId = e.value;
      this.pageNum = 1;
      this.getScale();
    },
    selectInfoScaleType(e) {},
    HandleSearch() {
      this.pageNum = 1;
      this.getScale();
    },
    closeScale(row, deOrRe) {
      if (deOrRe == "关闭") {
        this.$Modal.confirm({
          title: "提示",
          content: "是否关闭?",
          loading: true,
          onOk: () => {
            this.$post("Scale/DeleteScale", {
              scaleId: row.scaleId,
            }).then((res) => {
              if (res.data.code) {
                this.$Modal.remove();
                this.$Message.success({
                  content: "移除成功",
                  duration: 3,
                });
                this.getScale();
              } else {
                this.$Modal.remove();
                this.$Message.error({
                  content: "移除失败，请重试",
                  duration: 3,
                });
              }
            });
          },
        });
      } else {
        this.$post("Scale/ReductionScale", {
          scaleId: row.scaleId,
        }).then((res) => {
          if (res.data.code) {
            this.$Message.success({
              content: "还原成功",
              duration: 3,
            });
            this.getScale();
          } else {
            this.$Message.error({
              content: "还原失败，请重试",
              duration: 3,
            });
          }
        });
      }
    },
    getScale() {
      this.searching = true;
      // 获取用户列表
      this.$get("Scale/GetScaleList", {
        typeId: this.typeId,
        keyword: this.keyword,
        statusCode: this.bindStatus,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      }).then((res) => {
        this.recordTotal = res.data.total;
        this.tData = res.data.responseList;
        this.searching = false;
        this.$Message.success({
          content: "加载成功",
          duration: 3,
        });
      });
    },
    changeStatus(k) {
      if (k == true) {
        this.deOrRe = "开放";
        this.bindStatus = 0;
      } else {
        this.deOrRe = "关闭";
        this.bindStatus = 1;
      }
      this.pageNum = 1;
      this.getScale();
    },
    infoEdite(row) {
      this.imagesPath = "";
      this.signText =
        "原价为-1时不改原价，折扣为-1时不改折扣，类型为空时不改类型";
      this.scaleInfoForm = {
        isShow: true,
        modalTitle: "量表详情",
        scaleId: row.scaleId,
        scaleName: row.scaleName,
        type: row.scaleTypeId,
        price: row.price,
        discount: row.disCount,
        describe: row.describe,
        hint: row.hint,
        iamges: row.images,
      };
      this.scaleimages = "https://swapi.hxypsy.cn" + row.images;
      this.scaleInfo = true;
    },
    verdict(row) {
      this.$router.push({
        path: "/Verdict",
      });
      this.$Message.success({
        content: "加载成功",
        duration: 3,
      });
    },
    mulInfoEdite() {
      this.scaleInfo = true;
      this.signText =
        "原价为-1时不改原价，折扣为-1时不改折扣，类型为空时不改类型";
      this.scaleInfoForm = {
        isShow: false,
        modalTitle: "批量编辑量表",
        price: -1,
        discount: -1,
        classes: [],
      };
    },
    onSelect(selection) {
      let a = selection.map((x) => {
        return x.key;
      });
      this.rows = a;
    },
  },
};
</script>

<style lang="less" scoped="scoped">
.singnText {
  font-size: 0.5rem;
  margin-left: 4.375rem;
  margin-top: -2rem;
}
.editor {
  width: 100%;
  position: relative;
  z-index: 0;
}
.toolbar {
  border: 1px solid #ccc;
}
.text {
  border: 1px solid #ccc;
  min-height: 150px;
}
</style>
